@use "src/styles/abstracts" as *;

$component: "c-visitor-space-card-list";

.#{$component} {
	display: grid;
	list-style: none;
	grid-template-columns: minmax(0, 1fr);
	margin: 0;
	padding: 0;
	row-gap: $spacer-md;

	@include respond-at("md") {
		column-gap: $spacer-lg;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: max-content;
		row-gap: $spacer-lg * 2;
	}
}
