@use "src/styles/abstracts" as *;

.c-content-page-overview-block__header {
	opacity: 0;
}

.c-content-block {
	position: relative;

	> div {
		.c-content-block__header-bg-color {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			width: 100%;
			height: 0; // Will be overridden by a dynamic style in the ContentBlockPreview component
			z-index: -1; // put absolutely positioned header background below block content but above the .c-content-block div
		}

		.c-content-block-preview {
			z-index: get-z-layer("foreground");

			> .o-container {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					// No margins on header blocks, the block margins should handle this: https://meemoo.atlassian.net/browse/AVO-315
					margin: 0;
				}
			}
		}
	}
}

.c-content-page-preview .c-content-block > div {
	z-index: get-z-layer("foreground"); // Below mobile hamburger menu
}

.c-content-block-preview--dark {
	// Here you can overwrite styles on content-blocks for previews with a dark background
	.c-block-media-list {
		.c-meta-data__item {
			color: #bac7d1;
		}
	}

	.c-quote {
		.c-quote__text {
			color: #edeff2;
		}

		.c-quote__author {
			color: #d6dee3;

			.c-avatar {
				background-color: #557891;
			}
		}
	}
}
