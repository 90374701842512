@use "../../abstracts" as *;

$component: "p-account-my-folders";

.#{$component} {
	&__export--label {
		@include respond-to("xl") {
			display: none;
		}
	}

	&__export--icon {
		@include respond-at("xl") {
			display: none;
		}
	}

	&__toggle {
		@include respond-to("md") {
			display: none;
		}
	}

	&__search {
		&.c-tag-search-bar.c-tags-input.c-tag-search-bar--light .c-tags-input__control {
			border-color: $zinc-66;
		}
	}

	&__card-description {
		p {
			font-size: $font-size-xs;
			line-height: $line-height-4xl;
		}

		&-access {
			display: inline-flex;
			justify-content: space-between;
			align-items: center;
			color: $black;
			background-color: $lila;
			padding: 0 $spacer-xs;
			font-weight: $font-weight-bold;
			line-height: 2rem;
			border-radius: 2.5rem;
			margin-top: $spacer-xs;
		}
	}

	&__limited-access {
		&-wrapper {
			display: flex;
			justify-content: center;
			width: 100%;
			height: 100%;
			padding: $spacer-xs;
			margin: $spacer-lg 0;
			background-color: $lila;
		}
	}

	// Hide links active state on mobile
	&__link {
		display: flex;
		justify-content: space-between;

		// Hide icon on desktop
		&__hide-icon {
			display: none;
		}

		&__limited-access-icon {
			font-size: x-large;
		}

		@include respond-to("md") {
			background-color: $platinum;
			color: $black;
			font-weight: 400;
			display: flex;
			align-items: center;
			justify-content: space-between;

			span {
				visibility: visible;
			}
		}
	}

	@include respond-to("lg") {
		// Hide top-level sidebar a bit earlier
		// > :global(.l-sidebar__navigation) {
		> div:first-child {
			display: none;
		}
	}
}
