@use "src/styles/abstracts" as *;

.c-navigation {
	&__logo {
		height: 6.5rem;

		&--hamburger {
			height: 6.5rem;
			grid-row: 2/4;
			grid-column: 1;

			svg {
				pointer-events: none;
			}
		}

		&--list {
			cursor: pointer;
			margin: 1rem 6rem 1rem 0;

			@include respond-to(xxl) {
				margin: 3.5rem 0 3.5rem $spacer-md;
			}
		}
	}

	&__auth {
		border-radius: 0;

		@include respond-to(xxl) {
			padding: 0;
			margin: 0;

			.c-button__content {
				font-size: $font-size-sm;
				padding: 0;
				margin: 0;
				text-align: right;
				white-space: unset;
			}
		}
	}

	&__notifications {
		&-badge {
			position: relative;

			&::after {
				position: absolute;
				top: 0.8rem;
				right: 0.8rem;
				content: "";
				width: 0.8rem;
				height: 0.8rem;
				border-radius: 50%;
			}

			&--white {
				&::after {
					background-color: $white;
				}
			}

			&--teal {
				&::after {
					background-color: $teal;
				}
			}
		}
	}
}
