@use "src/styles/abstracts" as *;

.c-filter-menu-mobile {
	position: fixed;
	inset: 0;
	z-index: get-z-layer("filter-menu-mobile");
	background-color: $black;
	color: $white;
	overflow-y: auto;

	:global(.c-tag-list) {
		margin: $spacer-sm 0 $spacer-md;
	}

	:global(.c-tag-list__tag--large) {
		background-color: $teal;
		color: $black;
	}
}

/**
 * Elements
 */

.c-filter-menu-mobile__nav {
	justify-content: flex-start;
}

.c-filter-menu-mobile__back {
	margin-left: -$spacer-md;
	font-size: $font-size-md;

	span {
		color: $white;
	}
}

.c-filter-menu-mobile__tags {
	:global(.c-tag) {
		padding-right: 0;
		background-color: $teal;
	}
}

.c-filter-menu-mobile__filters {
	.c-filter-menu__filter {
		width: 100%;
		padding-right: $spacer-sm;
		font-weight: $font-weight-normal;
		text-align: left;
		border-radius: 0;
		border-top: 1px solid rgb(255 255 255 / 10%);

		&:active {
			border-color: black;
		}

		:global(.c-button__content) {
			display: flex;
			justify-content: space-between;
		}

		:global(.c-button__icon) {
			font-size: $font-size-lg;
		}

		&--active {
			background-color: $white;
			border-color: $white;
			color: $black;

			&:hover {
				background-color: $white;
				border-color: $white;
			}

			&:not(:active, :hover):focus {
				outline: none;
			}
		}
	}
}

.c-filter-menu-mobile__form {
	height: calc(100% - #{$c-navigation-height-md});
}

.c-filter-menu-mobile__option {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		background-color: $teal;
		width: 0.8rem;
		transform: scaleX(0);
		transition: transform $animate-default;
		z-index: get-z-layer("filter");
	}
}

/**
 * Modifiers
 */

.c-filter-menu-mobile--inline {
	border: 0.1rem solid rgb(255 255 255 / 10%);
}

.c-filter-menu-mobile--active {
	background-color: $white;
	color: $black;
}

.c-filter-menu-mobile__option--operative {
	&::before {
		transform: scaleX(1);
	}
}
