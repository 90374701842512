@use "sass:math";
@use "../abstracts" as *;

/**
* Elements
*/

.c-tag-search-bar.c-tags-input {
	.c-tags-input__control {
		border-radius: 2.4rem;
		background-color: $silver;
		border-color: $silver;

		&.c-tags-input__control--is-focused,
		&.c-tags-input__control--is-focused:hover {
			border-color: $teal;
			border-width: 0.2rem;
			box-shadow: none;
			transition: border $animate-default;
		}
	}

	.c-tags-input__value-container {
		padding-left: $spacer-md;
	}

	.c-tags-input__indicators {
		padding-right: 1.4rem;
	}

	/**
	* Modifiers
	*/

	&.c-tag-search-bar--md {
		.c-tags-input__control {
			@include tabs-border-radius;

			height: 100%;
			min-height: 0;
		}

		.c-tags-input__value-container {
			padding-left: $tabs-rounded-size;
		}
	}

	&.c-tag-search-bar--lg {
		.c-tags-input__control {
			min-height: 4.8rem;
			border-radius: 2.4rem;

			@include respond-at(md) {
				min-height: 6.4rem;
				border-radius: 3.2rem;
			}
		}

		.c-tags-input__value-container {
			padding-left: $spacer-sm;

			@include respond-at(md) {
				padding-left: 1.2rem;
			}

			&--is-multi.c-tags-input__value-container--has-value {
				@include respond-to(md) {
					padding-left: $spacer-xs;
				}
			}
		}

		.c-tags-input__indicators {
			padding-right: math.div($spacer-md, 2);

			@include respond-at(md) {
				padding-right: $spacer-md;
			}
		}
	}

	&.c-tag-search-bar--light {
		.c-tags-input__control {
			background-color: $white;
			border-color: $silver;

			&--is-focused,
			&--is-focused:hover {
				border-color: $black;
			}
		}
	}

	&.c-tag-search-bar--has-dropdown {
		.c-tags-input__control {
			height: 100%;
			min-height: 6.4rem;
			border-radius: 0 0 2.4rem 2.4rem;

			@include respond-at("md") {
				border-radius: 0 4.6rem 4.6rem 0;
				border-right: none;
			}
		}
	}
}

.c-tag-search-bar__value-placeholder {
	color: $neutral;
	margin-right: 1.4rem;

	@include respond-to(md) {
		display: none;
	}
}

.c-tag-search-bar__clear-label {
	text-decoration: underline;
}
