@use "src/styles/abstracts" as *;

$component: "c-Alert";

.#{$component} {
	background-color: $silver;
	display: flex;
	padding: $spacer-sm;

	.c-Alert__icon {
		font-size: 2rem;
		margin-right: 0.8rem;
	}

	.c-Alert__message {
		flex-grow: 1;
	}

	.c-Alert__close-icon {
		align-self: start;
		font-size: 2rem;
		margin-left: 0.8rem;
		padding: 0;
	}

	.c-Alert__title {
		font-weight: bold;
		margin-bottom: 0.4rem;
	}

	&__margin-bottom {
		margin-bottom: $spacer-lg;
	}

	&--blue {
		background-color: $light-blue;
	}
}
