@use "sass:map";
@use "src/styles/abstracts" as *;

.c-unread-marker {
	background-color: $zinc;
	width: 8px;
	height: 8px;
	min-width: 8px;
	margin-right: 8px;
	border-radius: 50%;

	&__active {
		background-color: $teal;
	}
}
