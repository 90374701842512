@use "../abstracts" as *;

$component: "c-tab";

.#{$component} {
	align-items: center;
	background-color: inherit;
	cursor: pointer;
	display: inline-flex;
	min-height: $tab-min-height;
	padding: $tab-vertical-padding 1.2rem;
	transition:
		background-color $animate-default,
		color $animate-default;

	@include respond-to("md") {
		font-size: $font-size-sm;
	}

	&:focus,
	&:focus-within,
	&:focus-visible {
		outline: 0.1rem solid $teal;
		outline-offset: -0.1rem;
	}

	/**
     * Elements
     */

	&__icon {
		display: inline-flex;
		font-size: $font-size-base;
		margin-right: $spacer-xs;

		@include respond-at("md") {
			font-size: $font-size-lg;
		}
	}

	/**
     * Modifiers
     */

	&.#{$component}--dark {
		background-color: $black;
		color: $white;

		@include respond-at("md") {
			min-height: $tab-min-height-md;
			padding: $tab-vertical-padding-md $spacer-md;
		}

		&.#{$component} {
			&--active {
				background-color: $platinum;
				color: $black;
			}
		}
	}

	&.#{$component}--light {
		position: relative;
		color: $neutral;

		@include respond-at("md") {
			min-height: $tab-min-height-md;
			padding: $tab-vertical-padding-md $spacer-sm;
		}

		&.#{$component} {
			&--active {
				color: $black;
				font-weight: $font-weight-bold;

				&::after {
					content: "";
					display: block;
					position: absolute;
					inset: 0;
					top: auto;
					height: 2px;
					background: $teal;
					transform: translateY(-2px);
				}
			}
		}
	}

	&--medium {
		min-height: $tab-min-height-medium;

		&:focus {
			outline: $teal solid;
			outline-offset: -6px;
		}
	}
}
