@use "sass:map";
@use "src/styles/abstracts" as *;

$c-hero-access-card-min-width: 57.2rem;

.c-hero {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: $spacer-2xl clamp(calc(54rem - #{$spacer-2xl}), 40vw, calc(70rem - #{$spacer-2xl}));
	padding: 0 calc(#{$spacer-1xl} * 3);
	color: $white;
	background-color: $black;

	&__content {
		z-index: get-z-layer("foreground");
		grid-column: 2 / 3;
		grid-row: 1 / 3;
		align-self: center;
		padding-left: calc(#{$spacer-1xl} * 2);
	}

	&__title {
		max-width: 32rem;
		padding-bottom: $spacer-1xl;
		line-height: $line-height-md;
	}

	&__description {
		max-width: 48.8rem;
		color: $zinc;
		line-height: $line-height-1xl;
	}

	&__link {
		color: $teal;
		font-weight: 700;
		line-height: $line-height-1xl;
	}

	&__image {
		position: relative;
		grid-column: 1 / 2;
		grid-row: 2 / 3;

		img {
			object-position: right center;
		}
	}

	@keyframes pulse {
		from {
			transform: translateY(0);
			opacity: 0.3;
		}

		to {
			transform: translateY(5px);
			opacity: 1;
		}
	}

	&__arrow-down {
		display: block;
		font-size: 3rem;
		line-height: 2;
		animation: pulse 1.5s infinite alternate ease-in;
	}

	@include respond-to(xl) {
		padding: 0 $spacer-2xl;

		&__content {
			padding-left: $spacer-2xl;
		}

		&__title {
			font-size: $font-size-3xl;
			max-width: 25rem;
		}
	}

	@include respond-to(lg) {
		grid-template-rows: $spacer-lg calc(54rem - #{$spacer-lg});
		padding: 0 $spacer-lg;

		&__content {
			padding-left: $spacer-lg;
		}

		&__title {
			padding-bottom: $spacer-sm;
		}
	}

	@include respond-to(md) {
		grid-template-columns: auto;
		grid-template-rows: 2fr 1fr 1fr;
		padding: $spacer-lg $spacer-md;

		&__content {
			grid-column: 1 / 2;
			grid-row: 2 / 4;
			justify-self: center;
			padding-left: 0;
		}

		&__description {
			max-width: 45rem;
		}

		&__image {
			grid-row: 1 / 3;

			img {
				object-position: center top;
			}
		}

		&--logged-out {
			max-height: 54rem;
		}
	}
}
