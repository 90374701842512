@use "src/styles/abstracts" as *;

.c-color-picker {
	height: 4.8rem;
	width: 100%;

	.c-color-picker--controls {
		display: flex;
	}

	.c-color-picker--trigger {
		width: 4.8rem;
		height: 4.8rem;
		border: 1px solid $zinc;
	}

	.c-color-picker--input {
		width: calc(100% - 48px);
		height: 4.8rem;
		border: 1px solid $zinc;
		border-left: 0;
		padding-left: 1.7rem;
		font-family: $font-family-primary;
		font-size: $font-size-base;
		line-height: $line-height-lg;

		:focus {
			border-color: $seagreen;
		}
	}
}
