@use "sass:color";
@use "src/styles/abstracts" as *;

.c-filter-form {
	display: flex;
	flex-direction: column;
	height: max-content;
}

/**
 * Elements
 */

.c-filter-form__header {
	padding: $spacer-sm 2rem;

	@include respond-at(md) {
		padding: 0 $spacer-lg $spacer-1xl;
	}
}

.c-filter-form__title {
	margin-top: 6.4rem;

	@include respond-to(md) {
		margin-top: unset;
		font-size: 2.8rem;
		text-align: center;
	}
}

.c-filter-form__body {
	max-height: 45vh;
	overflow-y: auto;

	// Allow "overflowing" effects on input fields
	padding-top: $spacer-xs;

	// Allow three-option dropdowns to not be cut off
	padding-bottom: $spacer-xs;

	@include respond-to(md) {
		flex-grow: 1;
	}

	// Ensure dropdowns fit in the minimal body (1 default field & add-new button)
	:global(.c-react-select__menu-list) {
		overflow: auto;
		max-height: $spacer-1xl * 4;
	}

	&--overflow {
		overflow: visible;
	}
}

.c-filter-form__footer {
	display: flex;
	padding: $spacer-md 2rem;
	width: 100%;

	@include respond-at(md) {
		padding: $spacer-md $spacer-lg;
	}
}

.c-filter-form__reset {
	margin-right: 4rem;
}

.c-filter-form__submit {
	flex-grow: 1;
}

/**
 * Modifiers
 */

.c-filter-form--inline {
	padding: 1.4rem $spacer-sm 1.4rem $spacer-md;
	border-bottom: 0.1rem solid color.adjust($white, $alpha: -0.8);

	:global(.c-checkbox) {
		height: 100%;

		:global(.c-checkbox__check-icon) {
			// checkbox size = spacer-sm, default 1px top -> subtract it to center
			top: calc((50% - ($spacer-sm / 2)) - 1px);
		}
	}
}
