@use "src/styles/abstracts" as *;

$component: "c-space-preview";

.#{$component} {
	&__summary {
		display: grid;
		grid-template-columns: 7.2rem auto;
		align-items: center;
		min-height: 4.8rem;

		> div {
			height: 100%;
		}
	}
}
