@use "src/styles/abstracts" as *;

$layout: "l-app";

.#{$layout} {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&--sticky {
		height: 100vh; // fallback for browsers that do not support custom css properties
		height: calc((var(--vh, 1vh) * 100)); // '100vh' for mobile excludes browser ui. Custom --vh prop is set in use-window-size.ts
		min-height: 72rem;
		min-height: calc((var(--vh, 1vh) * 100));
		overflow: hidden;

		.#{$layout}__main {
			min-height: 0;
		}
	}

	/**
	* Elements
	*/

	&__main {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		position: relative;
	}

	&__alerts-overlay {
		z-index: get-z-layer("alerts");
		position: absolute;
		top: $spacer-lg;
		align-self: center;
		display: grid;
		row-gap: $spacer-sm;
	}
}
