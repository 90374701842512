@use "src/styles/abstracts" as *;

.c-tag-list {
	display: flex;
	flex-wrap: wrap;
	gap: $spacer-xs;
	padding: 0;
	font-size: $font-size-sm;
	list-style: none;
}
