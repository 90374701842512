@use "sass:math";
@use "src/styles/abstracts" as *;

$component: "c-media-card";
$c-media-card-no-video-intended-spacing: 0.9rem;

.#{$component} {
	a {
		text-decoration: none;
	}

	&__wrapper {
		position: relative;
	}

	&:global(.c-media-card--no-link) {
		cursor: default !important;
		background-color: $silver;

		:global(.c-card__title-wrapper) {
			color: $grey;
		}
	}

	&__no-content-icon {
		color: $grey !important;
	}

	&--pointer {
		cursor: pointer;
	}

	&--key-user {
		border: 1px solid $mustard !important;
	}

	&__locally-available-container {
		margin-top: $spacer-sm;
		display: flex;
		flex-direction: row;
		gap: $spacer-sm;

		@include respond-to(xxl) {
			flex-direction: column;
		}
	}

	&__info-button {
		display: flex !important;
		justify-content: center !important;
		font-size: $font-size-sm !important;
		height: 3.7rem;
	}

	&__visit-button {
		display: flex !important;
		justify-content: center !important;
		font-size: $font-size-sm !important;
		height: 3.7rem;
	}

	&__close-button-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: $spacer-1xl;
	}

	&__close-button {
		width: 15rem;
	}

	&__infomodal-description {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $neutral;
	}

	&__locally-available-pill {
		position: absolute;
		right: $spacer-xs;
		top: $spacer-xs;
		font-weight: $font-weight-normal;
	}

	&__link {
		text-decoration: none;
	}

	&__header,
	&__no-content {
		&-wrapper {
			max-width: 100%;
			min-width: 100%;
			position: relative;
		}
	}

	// Override next/image
	&__header-wrapper {
		min-height: 13rem;

		&--list,
		&--grid {
			position: relative;

			> span {
				position: static !important;

				> img {
					position: static !important;
					height: auto !important;
				}
			}
		}

		&--list {
			span > img {
				max-height: unset !important;
				min-height: unset !important;
			}

			@include respond-at("md") {
				min-width: 248px;
			}
		}
	}

	:global(.c-card__bottom-wrapper) {
		// Ensures the absolutely positioned bookmark button that is rendered outside of the card's link is rendered in the right position
		position: relative;
	}

	:global(.c-card__header-wrapper) {
		// Leave room for the absolutely positioned bookmark button that is rendered outside of the card's link
		margin-right: 4.5rem;
	}

	&__header-icon {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 2.2rem;
		line-height: 0;
		color: $blue;
		padding: 0.25rem 0.5rem 0.5rem 0.25rem;
		background-color: $white;
	}

	&__header-duration {
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: $font-size-sm;
		background-color: $white;
		color: $grey;
		padding: 0.5rem 0.75rem;
	}

	:global(.c-card__subtitle-wrapper),
	:global(.c-card__children-wrapper) {
		line-height: 2.2rem;
	}

	&__no-content {
		color: $blue;
		font-variation-settings: 2.2rem;
		display: block;
		margin-bottom: $c-media-card-no-video-intended-spacing - $spacer-sm;

		&-wrapper {
			align-items: center;
			background-color: $platinum;
			color: $zinc;
			display: flex;
			min-height: 16rem;
			min-width: 24.8rem;
			place-content: center;

			.#{$component}__no-content {
				transform: translateY(-25%);
			}
		}
	}

	&__icon {
		font-size: $font-size-lg * math.div(11, 12);
		line-height: $line-height-lg;
	}

	&__toolbar {
		display: flex;
		flex-direction: row;
		flex-shrink: 0;
		position: absolute;
		right: $spacer-sm;
		top: $spacer-sm;

		:global(.c-dropdown__content-open) {
			box-shadow: $shadow-24-black-12;

			:global(.c-menu) {
				display: flex;
				flex-flow: column nowrap;
				padding: 0;

				button {
					border-radius: 0;

					&:not(:last-child) {
						border-bottom: 1px solid $silver;
					}
				}
			}
		}
	}
}
