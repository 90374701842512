@use "sass:color";
@use "src/styles/abstracts" as *;

.c-advanced-filter-fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: $spacer-sm;
	padding: 2rem;
	border-bottom: 1px solid color.adjust($black, $alpha: -0.8);
	background-color: $platinum;

	@include respond-at(md) {
		padding: $spacer-lg;
	}
}

.c-advanced-filter-fields__field-container {
	grid-column: span 2;

	:global(.c-form-control__input) {
		display: flex;

		:global(.c-button) {
			margin-left: $spacer-sm;
			flex-shrink: 0;
		}
	}
}

.c-advanced-filter-fields__dynamic-field {
	flex-grow: 1;
}
