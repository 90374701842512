@use "sass:color";
@use "../abstracts" as *;

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $font-weight-bold;
}

h1 {
	font-size: $font-size-4xl;
	line-height: $line-height-lg;
}

h2 {
	font-size: $font-size-3xl;
	line-height: $line-height-lg;
}

h3 {
	font-size: $font-size-2xl;
	line-height: $line-height-md;
}

h4 {
	font-size: $font-size-lg;
	line-height: $line-height-lg;
}

h5 {
	font-size: $font-size-2xm;
	line-height: $line-height-lg;
}

h6 {
	font-size: $font-size-md;
	line-height: $line-height-lg;
}

p {
	font-size: $font-size-base;
	line-height: $line-height-lg;
	margin-block-start: 0;
	margin-block-end: 0;
}

a {
	text-decoration: underline;
	color: inherit;

	&:focus-visible {
		outline-offset: 0.2rem;
		outline-color: $teal;
		outline-style: solid;
	}
}

small {
	font-size: $font-size-sm;
}

strong {
	font-weight: $font-weight-bold;
}

mark {
	background-color: transparent;
	background-image: linear-gradient(#0000, #0000), linear-gradient($teal-64, $teal-64);
	background-size:
		0,
		100% 50%; // height

	background-position:
		0,
		0 bottom; // vertical position

	background-repeat: no-repeat;
}
