@use "src/styles/abstracts" as *;

$component: "c-create-folder-button";

.#{$component} {
	padding-top: $spacer-xs;

	:global(.c-form-control__errors) {
		font-size: $font-size-sm;
	}
}
