@use "src/styles/abstracts" as *;

$component: "c-pagination-progress";

.#{$component} {
	@include text-ellipsis(true);

	color: $neutral;
	font-size: $font-size-sm;
	line-height: $line-height-3xl;
}
