@use "../../abstracts" as *;

.p-account-my-profile__general-info {
	background-color: $white;

	.p-account-my-profile__general-info-header,
	.p-account-my-profile__general-info-items {
		@include respond-at(md) {
			display: grid;
			grid-auto-flow: column;
			grid-template-columns: 3fr 1fr;
		}
	}

	dl {
		display: grid;
		grid-template-columns: 20rem 1fr;
		row-gap: 1.2rem;
		margin: 0;

		@include respond-to(sm) {
			grid-template-columns: 15rem 1fr;
		}
	}

	dt,
	dd {
		margin: 0;
	}

	.c-button {
		margin-top: 1.2rem;

		@include respond-at(md) {
			margin-top: -1.2rem;
		}
	}
}

.p-account-my-profile__edit {
	justify-self: flex-end;
}

.p-account-my-profile__general-info-alert {
	background-color: $silver;
	display: flex;

	.c-Alert__icon {
		font-size: 2rem;
		margin-right: 0.4rem;
	}

	.c-Alert__title {
		font-weight: bold;
		margin-bottom: 0.4rem;
	}
}

.p-account-my-profile__permissions {
	dl,
	dd,
	dt {
		margin: 0;
		padding: 0;
	}
}
