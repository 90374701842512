@use "sass:math";
@use "sass:color";
@use "src/styles/abstracts" as *;

$terms-of-service-background-color: #ebfbf8;

.p-terms-of-service {
	flex-grow: 1;
	min-height: 0;
	background: $terms-of-service-background-color;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: minmax(0, 1fr) 192px 96px;

	&__background {
		@include meemoo-logo-background($white);

		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: 350%;
		grid-column: 1 / 2;
		grid-row: 1 / 2; // Ensure no overlap with 192px gradient

		@include respond-at("sm") {
			background-size: 150%;
		}

		@include respond-at("lg") {
			background-size: 125%;
		}
	}

	&__gradient {
		pointer-events: none; // allow pointer through
		background: linear-gradient(0deg, $terms-of-service-background-color 0%, color.adjust($color: $terms-of-service-background-color, $alpha: -1) 100%);
		grid-column: 1 / 2;
		grid-row: 2 / 3; // 192px after X
		z-index: get-z-layer("foreground");
		transition: opacity $timing-default linear;

		&--hidden {
			opacity: 0;
		}
	}

	&__text {
		overflow: hidden;
		grid-column: 1 / 2;
		grid-row: 1 / 3; // the first X + 192px

		.l-container {
			display: flex;
			max-height: 100%;
			flex-direction: column;
		}
	}

	&__title {
		margin-top: $spacer-md * 2;
		font-size: $font-size-lg;
		line-height: $line-height-3xl;
		text-align: center;

		@include respond-at("md") {
			margin-top: $spacer-lg * 3;
			font-size: $font-size-3xl;
			line-height: $line-height-lg;
		}
	}

	&__content {
		margin-top: $spacer-1xl;
		max-height: 100%;
		min-height: 0;
		overflow-y: auto;

		@include respond-at("md") {
			margin-top: $spacer-lg * 2;
		}

		.c-content-page-preview {
			& > .c-content-block {
				background-color: transparent !important;
				word-break: break-word;
			}
		}
	}

	&__buttons-wrapper {
		grid-column: 1 / 2;
		grid-row: 3 / 4; // the last 96px
	}

	&__buttons {
		margin-top: $spacer-sm;
		display: flex;
		justify-content: center;
	}
}
