@use "sass:math";
@use "../abstracts" as *;

$spacer-12: math.div($spacer-md, 2);
$spacer-20: math.div($spacer-1xl, 2);
$spacer-48: $spacer-md * 2;
$spacer-64: $spacer-lg * 2;

/**
 * Padding
 */

$p: (
	"0": 0,
	"16": $spacer-sm,
	"20": $spacer-20,
	"24": $spacer-md,
	"32": $spacer-lg,
);

@include generate-utils("p", "padding", $p, ("md"));

// Top / Bottom
$py: (
	"0": 0,
	"8": $spacer-xs,
	"16": $spacer-sm,
	"24": $spacer-md,
	"32": $spacer-lg,
	"48": $spacer-48,
);

@include generate-utils("py", ("padding-top", "padding-bottom"), $py, ("md"));

// Left / Right
$px: (
	"0": 0,
	"8": $spacer-xs,
	"12": $spacer-12,
	"16": $spacer-sm,
	"20": $spacer-20,
	"24": $spacer-md,
	"32": $spacer-lg,
);

@include generate-utils("px", ("padding-left", "padding-right"), $px, ("md"));

// Top
$pt: (
	"0": 0,
	"8": $spacer-xs,
	"12": math.div($spacer-md, 2),
	"24": $spacer-md,
	"32": $spacer-lg,
	"56": $spacer-md + $spacer-lg,
	"80": $spacer-2xl,
);

@include generate-utils("pt", "padding-top", $pt, ("md"));

// Bottom
$pb: (
	"0": 0,
	"8": $spacer-xs,
	"24": $spacer-md,
	"48": $spacer-48,
	"56": $spacer-md + $spacer-lg,
	"32": $spacer-lg,
	"80": $spacer-2xl,
);

@include generate-utils("pb", "padding-bottom", $pb, ("md"));

// Left
$pl: (
	"8": $spacer-xs,
	"24": $spacer-md,
	"32": $spacer-lg,
);

@include generate-utils("pl", "padding-left", $pl, ("sm"));

// Right
$pr: (
	"8": $spacer-xs,
	"24": $spacer-md,
	"56": $spacer-md + $spacer-lg,
);

@include generate-utils("pr", "padding-right", $pr, ("sm", "md"));

/**
 * Margin
 */

$m: (
	"0": 0,
);

@include generate-utils("m", "margin", $m, ());

// Top
$mt: (
	"4": $spacer-xs,
	"8": $spacer-xs,
	"16": $spacer-sm,
	"24": $spacer-md,
	"32": $spacer-lg,
	"48": $spacer-48,
	"56": $spacer-md + $spacer-lg,
	"64": $spacer-64,
	"80": $spacer-2xl,
);

@include generate-utils("mt", "margin-top", $mt, ("md"));

// Bottom
$mb: (
	"0": 0,
	"8": $spacer-xs,
	"16": $spacer-sm,
	"24": $spacer-md,
	"32": $spacer-lg,
	"40": $spacer-1xl,
	"48": $spacer-48,
	"56": $spacer-md + $spacer-lg,
	"64": $spacer-64,
	"80": $spacer-2xl,
);

@include generate-utils("mb", "margin-bottom", $mb, ("md"));

// Left
$ml: (
	"4": $spacer-xxs,
	"8": $spacer-xs,
	"20": $spacer-20,
	"-12": - $spacer-12,
);

@include generate-utils("ml", "margin-left", $ml);

// Right
$mr: (
	"4": $spacer-xxs,
	"8": $spacer-xs,
);

@include generate-utils("mr", "margin-right", $mr);

// Top / Bottom
$my: (
	"8": $spacer-xs,
	"16": $spacer-sm,
	"32": $spacer-lg,
);

@include generate-utils("my", ("margin-top", "margin-bottom"), $my);

// Left / Right
$mx: (
	"16": $spacer-xxs,
	"-32": - $spacer-lg,
);

@include generate-utils("mx", ("margin-left", "margin-right"), $mx);

.u-page-bottom-padding {
	padding-bottom: $spacer-1xl;
}
