@use "src/styles/abstracts" as *;

.c-tag-search-bar-button {
	display: inline-flex;

	&:not(:active, :hover):focus {
		outline: 2px solid $teal;
		border-radius: 0.5rem;
	}

	@include more-clickable-element;
}
