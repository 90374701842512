@use "src/styles/abstracts" as *;

.c-breadcrumbs {
	font-size: $font-size-xs;
	color: $shade;

	&__list {
		@extend %reset-list;
	}

	&__item {
		display: inline-flex;
		align-items: center;
		margin-bottom: $spacer-xxs;
	}

	&__link {
		font-size: $font-size-xs;
		/* stylelint-disable-next-line value-no-vendor-prefix */
		display: -webkit-box;
		justify-content: center;
		align-items: center;
		margin: 0 $spacer-xxs;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		/* stylelint-disable-next-line property-no-unknown */
		line-clamp: 1;

		&--active {
			-webkit-line-clamp: 2;
			/* stylelint-disable-next-line property-no-unknown */
			line-clamp: 2;
		}
	}
}
