@use "src/styles/abstracts" as *;

$component: "c-request-status-badge";

.#{$component} {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	min-height: $spacer-md;

	:global(.c-badge) {
		margin-right: $spacer-xs;
	}
}
