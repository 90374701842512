@use "src/styles/abstracts" as *;

$page: "p-admin-visitor-spaces";

.#{$page} {
	&__header {
		height: 5.6rem;
		display: flex;
	}

	&__search {
		width: 100%;
	}

	&__error {
		text-align: center;
		color: $neutral;
	}
}
