@use "sass:color";
@use "src/styles/abstracts" as *;

$c-toggle-black-32: color.adjust($black, $alpha: -0.68);
$c-toggle-white-40: color.adjust($white, $alpha: -0.6);
$c-toggle-zinc: $zinc-66;

/* stylelint-disable no-descending-specificity */
.c-toggle {
	display: inline-flex;
	align-items: center;
	height: 4.8rem;
	padding: 0 1.2rem;

	.c-toggle {
		&__option {
			width: 4rem;
			height: 4rem;

			&--active {
				color: $c-toggle-black-32;

				&:hover {
					color: $c-toggle-black-32;
					cursor: default;
				}
			}
		}
	}

	&--bordered {
		border: 1px solid $c-toggle-zinc;
		border-radius: 2.4rem;
	}

	&--dark {
		background-color: $black;

		.c-toggle__option {
			color: $white;

			&:hover {
				color: $zinc;
			}

			&--active {
				color: $c-toggle-white-40;

				&:hover {
					color: $c-toggle-white-40;
					cursor: default;
				}
			}
		}
	}
}

/* stylelint-enable no-descending-specificity */
