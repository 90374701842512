@use "src/styles/abstracts" as *;

.c-refinable-radio-button {
	&__refine {
		display: grid;
		grid-template-rows: 1fr auto 1fr;
		grid-template-columns: min-content auto;
		width: 100%;
		height: 100%;
		margin-top: $spacer-xxs;

		&::before {
			content: "";
			display: block;
			grid-row: 1/4;
			background-color: $silver;
			height: 100%;
			width: 0.2rem;
			margin: 0 $spacer-md 0 calc($c-check-radio-size / 2);
		}

		:global(.c-dropdown__content-open) {
			max-height: 30rem;
			overflow-y: auto;
			overflow-x: hidden;
		}

		&--disabled {
			.c-refinable-radio-button__dropdown,
			.c-refinable-radio-button__dropdown-info {
				opacity: 0.5;
			}
		}
	}

	&__dropdown {
		grid-row: 2;
		width: 100%;

		button {
			div {
				max-width: 310px;

				span:first-child {
					white-space: nowrap !important;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		&-info {
			color: $neutral;
			font-size: $font-size-xs;
			grid-row: 3;
			margin-top: $spacer-xxs;
		}
	}

	&__checkbox-list {
		&-item {
			border-top: 0.1rem solid $zinc;
			padding: $spacer-xxs $spacer-sm;

			&:global(.is-checked) {
				background-color: $teal-medium;
			}

			&:first-of-type {
				border-top: none;
			}
		}
	}

	&__dropdown,
	&__dropdown-info {
		transition: opacity $animate-default;
		opacity: 1;
	}
}
