@use "sass:map";
@use "sass:math";
@use "src/styles/abstracts" as *;

$component: "c-table";
$c-table-border: 1px solid $silver;

.#{$component} {
	width: 100%;
	max-width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	display: table;
	overflow-x: auto;

	&__scroller {
		max-width: 100%;
		overflow-x: scroll;

		// Note: desktop resolutions will rarely need to hide overflow, disable to allow the shadow
		@include respond-at("lg") {
			overflow: initial;
		}
	}

	&__wrapper {
		&--body {
			background-color: $white;
			border-bottom: $c-table-border;
			border-top: $c-table-border;

			@include respond-at("lg") {
				box-shadow: $shadow-24-black-08;
			}
		}
	}

	&__row {
		border-bottom: $c-table-border;
	}

	&__footer {
		padding-right: $spacer-xs;
		padding-left: $spacer-md;
	}

	&__cell {
		text-align: left;
		padding: $spacer-sm $spacer-md;
		max-width: 12rem;
		overflow: hidden;
		text-overflow: ellipsis;

		&:last-child {
			text-align: right;
			padding-right: $spacer-xs;
		}

		&--body {
			font-size: $font-size-sm;
			line-height: $line-height-lg;
		}

		&--header {
			@include text-ellipsis;

			position: relative;
			font-weight: $font-weight-bold;
			font-size: $font-size-xs;
			line-height: $line-height-2xl;
			color: $neutral;
			user-select: none;

			&.#{$component} {
				&__cell--active {
					color: $black;
				}
			}

			.#{$component} {
				&__icon {
					font-size: $font-size-base;
					position: absolute; // This is enough to correctly position it
				}
			}
		}
	}

	&__copy {
		color: $neutral;

		&:hover,
		&:active,
		&:focus,
		&:focus-within {
			color: $teal !important;
		}
	}
}

.c-table--no-padding-last-column td:last-child {
	padding-top: 0;
	padding-bottom: 0;
}
