@use "src/styles/abstracts" as *;

.c-input {
	position: relative;
}

/**
 * Elements
 */

.c-input__field {
	@include reset-input-field;

	background-color: $white;
	border: 1px solid $zinc;
	font-size: $font-size-base;
	height: 4.8rem;
	outline: 1px solid transparent;
	padding: 1.4rem 1.6rem;

	&:focus {
		border-color: $teal;
	}
}

.c-input__icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: inline-flex;
	font-size: $font-size-lg;
	color: $neutral;
	pointer-events: none;

	.c-button__icon {
		@include more-clickable-element;
	}
}

/**
 * Modifiers
 */

// Sizes
.c-input--lg {
	.c-input__field {
		height: 6.4rem;
	}
}

.c-input--md {
	.c-input__field {
		height: 5.6rem;
	}
}

//  Variants
.c-input--icon-start {
	.c-input__field {
		padding-left: 4.5rem;
	}

	.c-input__icon {
		left: 1.3rem;
	}
}

.c-input--icon-end {
	.c-input__field {
		padding-right: 4.5rem;
	}

	.c-input__icon {
		right: 1.3rem;
	}
}

.c-input--disabled {
	.c-input__field {
		border-color: $silver;
		cursor: not-allowed;
	}
}

.c-input--rounded {
	.c-input__field {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		border-radius: 2.4rem;
	}

	&.c-input--lg {
		.c-input__field {
			border-radius: 3.2rem;
		}
	}

	&.c-input--md {
		.c-input__field {
			border-radius: $input-border-radius;
		}
	}

	&.c-input--icon-start {
		.c-input__field {
			padding-left: 5.4rem;
		}

		.c-input__icon {
			left: 2rem;
		}
	}

	&.c-input--icon-end {
		.c-input__field {
			padding-right: 5.4rem;
		}

		.c-input__icon {
			right: 2rem;
		}
	}

	&.c-input--icon-clickable {
		.c-input__icon {
			pointer-events: all;
		}
	}
}

.c-input--icon--double {
	&.c-input--icon-end {
		.c-input__icon .c-button:not(:last-child) {
			margin-right: $spacer-xxs;
		}
	}
}

// Colors
.c-input--grey {
	/* stylelint-disable-next-line no-descending-specificity */
	.c-input__field {
		background-color: $silver;
		border-color: $silver;

		&:focus {
			border-color: $teal;
		}
	}
}

.c-input--grey-border {
	/* stylelint-disable-next-line no-descending-specificity */
	.c-input__field {
		border-color: $silver;

		&:focus {
			border-color: $teal;
		}
	}
}

.c-input--black-border {
	/* stylelint-disable-next-line no-descending-specificity */
	.c-input__field {
		border-color: $black;

		&:focus {
			border-color: $teal;
		}
	}
}

// States

/* stylelint-disable-next-line no-duplicate-selectors */
.c-input {
	&:not(.c-input--grey, .c-input--disabled, :focus-within):hover {
		/* stylelint-disable-next-line no-descending-specificity */
		.c-input__field {
			outline-color: $zinc;
		}
	}
}
