@use "../abstracts" as *;

.u-text-ellipsis {
	@include text-ellipsis;
}

// Use a CSS multi-line truncator to avoid repaint-heavy packages
// Note: include `1` for completeness sake

$lines: (1, 2, 3, 4, 5, 6, 7);

@each $var in $lines {
	.u-text-ellipsis--#{$var} {
		overflow: hidden;
		text-overflow: ellipsis;
		/* stylelint-disable-next-line value-no-vendor-prefix */
		display: -webkit-box;
		-webkit-line-clamp: $var; /* number of lines to show */
		/* stylelint-disable-next-line property-no-unknown */
		line-clamp: $var;
		-webkit-box-orient: vertical;
	}
}

.u-text-break {
	word-break: break-all;
}

.u-text-no-decoration {
	text-decoration: none;
}

.u-text-default-font {
	font-family: $font-family-primary;
}

/**
 * Alignment
 *
 * usage: u-text-left
 */

$align: (left, right, center);

@include generate-utils("text", "text-align", $align);

/**
 * Size
 * eg: u-font-size-14
 */

$size: (
	"12": $font-size-xs,
	"14": $font-size-sm,
	"18": $font-size-md,
	"22": 2.2rem,
	"24": $font-size-lg,
);

@include generate-utils("font-size", "font-size", $size);

/**
 * Weight
 */

$weight: (
	"400": $font-weight-normal,
);

@include generate-utils("font-weight", "font-weight", $weight);

/**
 * Line-height
 */

$height: (
	"1-25": $line-height-1xl,
	"1-4": $line-height-3xl,
);

@include generate-utils("line-height", "line-height", $height);

/**
 * Whitespace
 */

$whitespace: (nowrap);

@include generate-utils("whitespace", "white-space", $whitespace);
