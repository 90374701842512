@use "src/styles/abstracts" as *;

$component: "c-content-block__image-grid";

.#{$component} {
	.c-block-grid {
		column-gap: 3.4rem;
		row-gap: 8rem;
		justify-content: center;

		&__item {
			margin: 0 !important;

			.u-spacer-top-s {
				margin-top: $font-size-lg !important;
			}

			h3 {
				font-size: $font-size-md !important;
			}
		}
	}

	@include respond-to(lg) {
		.c-block-grid--flex-3 {
			flex-direction: column;

			.c-block-grid__item {
				width: 100% !important;
			}
		}
	}
}
