@use "../../../../styles/abstracts" as *;

$component: "c-request-material";

.#{$component} {
	&__maintainer {
		padding: $spacer-sm $spacer-lg;
		background-color: $platinum;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-details {
			color: $neutral;
			text-align: end;
			font-size: $font-size-sm;
		}

		&-logo {
			position: relative;
			width: 8rem;
			height: 5rem;
		}
	}

	&__material {
		padding: $spacer-md $spacer-lg;
		border-bottom: 0.2rem solid $platinum;
		color: $black;

		&:focus,
		&:focus-within {
			outline: 0.2rem solid $teal;
			outline-offset: -0.2rem;
		}

		&-link {
			text-decoration: none;
		}

		&-label {
			font-weight: 900;
			margin-bottom: $spacer-xxs;
			word-break: break-word;

			&-icon {
				color: $blue;
				margin-right: $spacer-xs;
			}
		}

		&-id {
			font-size: $font-size-sm;
			color: $neutral;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__content {
		padding: $spacer-lg;
		color: $black;

		dl {
			margin-top: 0;
		}

		&-label {
			font-weight: 900;
			margin-bottom: $spacer-xs;
		}

		&-value {
			margin-left: 0;
			margin-bottom: $spacer-md;
		}
	}

	&__footer-container {
		padding: $spacer-lg;
	}

	&__verstuur-button {
		background-color: $black !important;
		color: $white !important;
		margin-bottom: $spacer-sm;

		&:hover {
			background-color: $white !important;
			color: $zinc !important;
			border: 0.1rem solid $zinc !important;
		}
	}

	&__voeg-toe-button {
		background-color: $white;
		color: $black;
		border: 0.1rem solid $black !important;
		font-weight: 600 !important;
		margin-bottom: $spacer-sm;

		&:hover {
			border: 0.1rem solid $black;
			background-color: $black;
			color: $white;
		}
	}

	&__annuleer-button {
		&:hover {
			border: 0.1rem solid $zinc;
		}
	}

	&__radio-buttons-container {
		display: flex;
		flex-direction: column;
	}

	&__radio-button {
		margin-bottom: $spacer-sm;
	}

	&__reason-input {
		textarea {
			min-height: 14rem !important;
			resize: none;
		}
	}

	&__reason-error {
		color: $cherry;
		display: block;
		margin-bottom: 1rem;
	}
}
