@use "src/styles/abstracts" as *;

$component: "c-media-card-list";

// Sidebar
$media-card-list-two-columns-gutter: $spacer-md;
$media-card-list-two-columns-gutter-md: $spacer-sm;

// Masonry
$media-card-list-masonry-gutter: $media-card-list-two-columns-gutter;
$media-card-list-masonry-gutter-md: $spacer-lg;

@mixin media-card-list-gutters($gutter) {
	.#{$component} {
		&__column {
			& > div,
			article {
				margin-bottom: $gutter;
			}
		}
	}
}

.#{$component} {
	a:focus-visible {
		outline-style: auto;
	}

	&__content {
		display: flex;
		width: 100%;
		min-width: 0;
	}

	&__column {
		background-clip: padding-box;
	}

	&__fake-height-spacer {
		height: 0;
	}

	// Modifiers

	// two-column grid
	&--two-columns {
		@include media-card-list-gutters($media-card-list-two-columns-gutter);

		margin-bottom: $media-card-list-two-columns-gutter;

		.#{$component} {
			&__sidebar {
				margin-bottom: $media-card-list-two-columns-gutter;
			}
		}

		@include respond-at("md") {
			@include media-card-list-gutters($media-card-list-two-columns-gutter-md);

			margin-bottom: $media-card-list-two-columns-gutter-md;

			&:not(.c-media-card-list--no-sidebar) {
				display: grid;
				grid-template-columns: 27.8rem 1fr;
				gap: $media-card-list-two-columns-gutter-md * 2;
			}
		}
	}

	// container offset, column-based grid
	// replace when/if switching masonry libraries
	&--masonry {
		@include media-card-list-gutters($media-card-list-masonry-gutter);

		margin: 0 (0 - $media-card-list-masonry-gutter / 2);

		.#{$component} {
			&__column {
				padding: 0 $media-card-list-masonry-gutter / 2;
			}
		}

		:global(.c-card--padded-content),
		:global(.c-card--padded-both) {
			:global(.c-card__header-wrapper) {
				margin-bottom: $spacer-xxs;
			}
		}

		@include respond-at("md") {
			@include media-card-list-gutters($media-card-list-masonry-gutter-md);

			margin: 0 (0 - $media-card-list-masonry-gutter-md / 2);

			.#{$component} {
				&__column {
					padding: 0 $media-card-list-masonry-gutter-md / 2;
				}
			}
		}
	}
}
