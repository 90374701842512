@use "src/styles/abstracts" as *;

.c-sidebar {
	display: grid;
	grid-template-rows: auto;
	grid-auto-rows: 1fr;
	height: 100%;
	width: 100%;
	overflow: hidden;

	&__title {
		padding: $spacer-lg;
		padding-top: $spacer-2xl;
		color: $zinc;
		font-size: $font-size-1xl;
	}
}
