/**
 * Resets
 */

%reset-list {
	padding: 0;
	margin: 0;
	list-style: none;
}

%reset-button {
	padding: 0;
	border: none;
	background: none;
	color: inherit;
}
