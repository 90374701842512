@use "src/styles/abstracts" as *;

$component: "c-pagination-bar";

@mixin c-pagination-bar-two-item-justify {
	& > span,
	> div,
	> button {
		&:nth-child(2n + 1) {
			justify-self: left;
		}

		&:nth-child(2n + 2) {
			justify-self: right;
			margin-right: -$button-horizontal-whitespace;
		}
	}
}

@mixin c-pagination-bar-three-item-justify {
	grid-template-columns: repeat(3, 1fr);

	& > span,
	> div,
	> button {
		&:nth-child(3n + 1) {
			justify-self: left;
		}

		&:nth-child(3n + 2) {
			justify-self: right;
		}

		&:nth-child(3n + 3) {
			justify-self: right;
			margin-right: -$button-horizontal-whitespace;
		}
	}
}

.#{$component} {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: min-content;
	align-items: center;

	&:not(.#{$component}--back-to-top) {
		@include c-pagination-bar-two-item-justify;
	}

	&__back-to-top-wrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		grid-row-start: 1;
		grid-column: 1 / 3;
		justify-self: right;
		margin-right: -$button-horizontal-whitespace;
		border-bottom: $divider-grey;
	}

	&--back-to-top {
		@include respond-to("md") {
			@include c-pagination-bar-two-item-justify;

			.#{$component}__back-to-top {
				margin-right: -$button-horizontal-whitespace;
			}
		}

		@include respond-at("md") {
			@include c-pagination-bar-three-item-justify;
		}

		.#{$component} {
			&__back-to-top-wrapper {
				@include respond-at("md") {
					grid-column: 3 / 4;
					border-bottom: none;
				}
			}
		}
	}
}
