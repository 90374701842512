@use "src/styles/abstracts" as *;

$component: "c-visit-summary";

.#{$component} {
	@include blade-text-styling;

	padding: 0 $spacer-sm;

	@include respond-at("md") {
		padding: 0 $spacer-lg;
	}
}
