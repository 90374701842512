@use "sass:map";
@use "sass:color";
@use "src/styles/abstracts" as *;

$teal-24: color.adjust($teal, $alpha: -0.76);
$component: "c-list-navigation";

.#{$component} {
	max-width: 100%;
	min-width: 0; // avoid flex-pushing

	&__list {
		@extend %reset-list;
	}

	&__item {
		display: block;

		&--active {
			font-weight: 700;
		}

		&:hover {
			cursor: pointer;

			@include respond-at(xxl) {
				background-color: $silver;
			}
		}

		&:not(:active, :hover):focus {
			outline: 1px solid $teal;
		}

		&--no-interaction {
			&:hover {
				background-color: inherit;
				cursor: default;
			}

			&:not(:active, :hover):focus {
				outline: inherit;
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		text-decoration: none;
		padding: 1.6rem 1.6rem 1.6rem $spacer-lg;

		&--indent {
			&--1 {
				padding-left: $spacer-lg * 2;
			}

			&--2 {
				padding-left: $spacer-lg * 3;
			}
		}
	}

	&__button:global(.c-button) {
		height: auto;
		padding: 1.2rem $spacer-sm;
		padding-left: $spacer-md;
		text-align: left;
	}

	&__divider {
		height: 0.1rem;
		margin: $spacer-xs $spacer-md;
		background-color: $silver;
	}

	&--white {
		.c-list-navigation__item--active {
			background-color: $teal-24;
			border-right: 0.4rem solid $teal;

			&:hover {
				background-color: $teal-24;
			}
		}
	}

	&--platinum {
		/* stylelint-disable-next-line no-descending-specificity */
		.c-list-navigation__item--active {
			background-color: $black;
			color: white;

			&:hover {
				background-color: $black;
			}
		}
	}
}
