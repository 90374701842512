@use "src/styles/abstracts" as *;

.c-icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	color: inherit;
	font-family: $font-family-icons-light;
	font-size: inherit;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: $line-height-base;
	white-space: nowrap;

	// Enable Ligatures
	font-feature-settings: "liga";
	font-variant-ligatures: discretionary-ligatures;
	letter-spacing: 0;

	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/**
 * Modifiers
 */

.c-icon--solid {
	font-family: $font-family-icons-solid;
}
