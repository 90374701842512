@use "src/styles/abstracts" as *;

$component: "c-date-input";

.#{$component} {
	width: 100%;

	&__label {
		font-weight: bold;
		padding-bottom: 15px;
	}
}
