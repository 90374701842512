@use "sass:math";
@use "src/styles/abstracts" as *;

.c-request-access-blade {
	:global(.c-input--textarea .c-input__field) {
		@include respond-to("sm") {
			min-height: 10rem;
		}
	}

	&__error {
		color: $cherry;
		margin-bottom: $spacer-xs;
	}
}
