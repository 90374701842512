@use "src/styles/abstracts" as *;

$component: "c-duration-range-input";

.#{$component} {
	width: 100%;
	display: grid;
	grid-template-rows: repeat(2, 1fr);
	gap: $spacer-sm;
}
