@use "sass:math";
@use "src/styles/abstracts" as *;

$component: "c-add-to-folder-blade";
$add-to-collection-checkbox-padding: $spacer-xs + $spacer-xxs;

.#{$component} {
	&__list {
		@extend %reset-list;

		margin-top: $spacer-lg;

		// CreateFolderButton
		&-button {
			:global(.u-px-24) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}

			:global(.c-content-input) {
				padding-top: $spacer-sm;

				@include respond-at("md") {
					padding-top: $spacer-md;
				}
			}

			:global(.c-form-control__errors) {
				padding-bottom: $spacer-sm;

				@include respond-at("md") {
					padding-bottom: $spacer-md;
				}
			}

			// break rule of 8 for visual alignment
			:global(.c-content-input__icon) {
				margin-left: -0.9rem;
				margin-right: 1.6rem;
			}
		}

		&-item {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			cursor: pointer;

			&:active,
			&:focus,
			&:focus-visible {
				outline: none;
				border: none;
			}

			&__checkbox {
				flex-shrink: 0;

				// Expand clickable surface without breaking container
				box-sizing: content-box;
				padding: $add-to-collection-checkbox-padding;
				margin-left: 0 - $add-to-collection-checkbox-padding;
			}

			&__label {
				flex-grow: 1;
				flex-shrink: 1;
				padding: $spacer-sm;

				@include respond-at("md") {
					padding-top: $spacer-md;
					padding-bottom: $spacer-md;
				}
			}

			&__count {
				flex-shrink: 0;
				flex-basis: 6rem;
				text-align: right;
			}

			&:not(:last-child) {
				border-bottom: 1px solid $black-20;
			}
		}
	}
}
