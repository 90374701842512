@use "../abstracts" as *;

$l-container-padding: $spacer-sm;
$l-container-padding-xs: 2rem;

.l-container {
	max-width: $max-page-width;
	width: 100%;
	padding: 0 $l-container-padding;
	margin: 0 auto;

	@include respond-at(xs) {
		max-width: $max-page-width;
		padding: 0 $l-container-padding-xs;
	}

	&--edgeless {
		&-to-lg {
			@include respond-to("lg") {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
