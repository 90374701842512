@use "src/styles/abstracts" as *;

$page: "p-cp-requests";

// TODO: make styles reusable (see visitors)

.#{$page} {
	&__header {
		@include respond-at("md") {
			display: flex;
			flex-flow: row-reverse nowrap;

			// Can't use &__status-filter here due to ScrollableTabs implementation
			& > div:last-child {
				flex-grow: 1;
			}
		}
	}

	&__search {
		margin-bottom: $spacer-md;

		@include respond-at("md") {
			flex-basis: 34.1rem;
			margin-bottom: 0;
			margin-left: $spacer-sm;
		}
	}

	&__edit {
		color: $grey;
		cursor: pointer;
		font-size: $font-size-lg;
		padding-right: $spacer-lg;
		transform: translateY(0.2rem); // shift if to center on single-line
	}

	tr {
		@include respond-to("lg") {
			td,
			th {
				&:nth-child(2),
				&:nth-child(3) {
					display: none;
				}
			}
		}
	}

	tbody {
		tr {
			cursor: pointer;
		}
	}
}
