@use "sass:map";
@use "sass:math";
@use "sass:color";
@use "src/styles/abstracts" as *;

$component: "c-visitor-spaces-dropdown";
$border-radius: 4.6rem;
$border-radius-sm: 2.4rem;
$border-width: 0.2rem;

.#{$component} {
	position: relative;

	&__active {
		background-color: $black;
		color: $white;
		height: 100%;
		min-height: 6.8rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1.4rem $spacer-lg;
		border: $border-width solid $silver;
		transition: border-radius $animate-default;
		border-radius: $border-radius-sm $border-radius-sm 0 0;
		border-bottom: none;

		@include respond-at("md") {
			border-radius: $border-radius 0 0 $border-radius;
			border: $border-width solid $silver;
			border-right: none;
		}

		&-content {
			overflow: hidden;
		}

		&-label {
			font-size: $font-size-md;
			font-weight: $font-weight-bold;
			line-height: 2rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-info {
			font-size: $font-size-xs;
			line-height: 1.6rem;
		}

		&-icon {
			transform: rotate(0);
			margin-left: $spacer-sm;
			transition: transform $animate-default;
		}
	}

	&__list {
		position: absolute;
		background-color: $white;
		top: 100%;
		left: 0;
		right: 0;
		opacity: 0;
		min-width: 100%;
		box-shadow: $shadow-24-black-12;
		transition: opacity $animate-default;
		pointer-events: none;
		z-index: get-z-layer("foreground");

		@include respond-at("md") {
			width: 26.5rem;
		}
	}

	&__option {
		width: 100%;

		&-label {
			padding: 1.2rem $spacer-md;
			overflow: hidden;
		}

		&:hover,
		&:active,
		&:focus {
			background-color: $silver;
		}

		&:focus,
		&:focus-within {
			outline: 0.2rem solid $teal;
		}
	}

	&--open {
		.#{$component} {
			&__list {
				opacity: 1;
				pointer-events: all;
			}

			&__active {
				@include respond-at("md") {
					border-radius: $border-radius 0 0 0;
				}

				&-icon {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&--selectable {
		.#{$component} {
			&__active {
				&:active,
				&:focus,
				&:focus-within {
					outline: none;
					border-color: $teal;
				}
			}
		}

		&:hover {
			cursor: pointer;
		}
	}
}
