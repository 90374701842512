@use "sass:map";
@use "src/styles/abstracts" as *;

.c-footer {
	position: relative;
	z-index: get-z-layer("base");
	color: $neutral;
	background-color: $white;

	&__wrapper {
		display: grid;
		max-width: $max-page-width;
		padding: 0 2rem;
		margin: 0 auto;

		:global(a) {
			display: block;
			margin-bottom: $spacer-xs;
			text-decoration: none;
			color: $shade;
			font-size: $font-size-sm;

			&:hover {
				text-decoration: underline;
				color: $seagreen;
			}
		}
	}

	&__links {
		&__section-title {
			font-weight: bold;
			color: black;
			margin-bottom: $spacer-xs;
			font-size: $font-size-base;
		}
	}

	// Desktop
	@include respond-at(md) {
		&__wrapper {
			grid-template-columns: 400fr 270fr 310fr 400fr 310fr;
			grid-template-rows: 1fr;
			height: 21rem;
			margin-top: 5rem;
		}

		&__links {
			&__left {
				justify-self: start;
				margin-top: -1.5rem;

				img {
					width: 16.5rem;
					height: auto;
				}
			}

			&__section1 {
				justify-self: start;
			}

			&__section2 {
				justify-self: start;
			}

			&__section3 {
				justify-self: start;
				padding-left: 7rem;
				position: relative;

				&::before {
					content: "";
					border-left: 1px solid $zinc;
					width: 1px;
					height: 10rem;
					left: 0;
					position: absolute;
				}
			}

			&__right {
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				> div {
					font-size: $font-size-xs;
				}
			}
		}
	}

	// Mobile
	@include respond-to(md) {
		&__wrapper {
			grid-template-columns: 2fr 1fr;
			grid-template-rows: 1fr 1fr 0.6fr 1fr;
			height: auto;
			padding: 4rem 3rem;
		}

		&__links {
			&__left {
				grid-column-start: 1;
				grid-row-start: 1;
				justify-self: start;

				img {
					width: 16.5rem;
					height: auto;
				}

				:global(.c-logo) {
					display: block;
				}

				// ARC-1786: dirty fix for iPhone mobile view on safari
				.mobile-safari-logo-hide {
					display: none;
				}

				// ARC-1786: dirty fix for iPhone mobile view on safari
				.mobile-safari-logo {
					display: block;
				}
			}

			&__section1 {
				grid-column-start: 1;
				grid-row-start: 2;
				justify-self: start;
			}

			&__section2 {
				grid-column-start: 1;
				grid-row-start: 3;
				justify-self: start;
			}

			&__section3 {
				grid-column: 1 / 2;
				grid-row-start: 4;
				justify-self: start;
				padding-top: 4.5rem;
				position: relative;
				display: flex;
				flex-direction: column;

				&::before {
					content: "";
					border-top: 1px solid $zinc;
					width: 100%;
					height: 1px;
					top: 0;
					position: absolute;
				}
			}

			&__right {
				grid-column-start: 2;
				grid-row: 1 / 3;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				padding: 2rem 0;

				> div {
					font-size: $font-size-xs;
				}
			}
		}
	}
}
