@use "sass:map";
@use "sass:math";
@use "src/styles/abstracts" as *;

.c-blade {
	background-color: $white;
	bottom: 0;
	display: grid;
	grid-template-rows: auto auto 1fr auto;
	margin-left: 4rem;
	max-height: 100%;
	max-width: $blade-max-width;
	width: 100%;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transform: translateX(100%);
	transition:
		transform $animate-default,
		visibility $animate-default;
	visibility: hidden;
	z-index: get-z-layer("blade");

	&__top-bar-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&__back-container {
		color: $black;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:hover {
			color: $neutral;
		}
	}

	&__close-button {
		grid-row-start: 1;
		justify-self: flex-end;
		margin: 2rem;
		z-index: get-z-layer("blade");

		&--absolute {
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	&__title {
		color: $black;
		font-size: $font-size-lg;
		line-height: $line-height-lg;
		padding: 0 ($spacer-lg + $spacer-md) $spacer-lg $spacer-sm;

		@include respond-to(md) {
			text-align: center;
		}

		@include respond-at(md) {
			font-size: $font-size-lg;
			padding: 0 ($spacer-lg + $spacer-md) $spacer-lg $spacer-lg;

			// Force line height to be an integer, avoids .5px misalignments in datepickers in blades
			line-height: $font-size-3xl * (math.div(56, 48));
		}

		&-wrapper {
			grid-row-start: 2;
		}
	}

	&__body-wrapper {
		grid-row-start: 3;
		overflow-y: auto;
		overflow-x: hidden;

		div {
			white-space: pre-line;
		}
	}

	&__footer-wrapper {
		grid-row-start: 4;
	}

	&__overlay {
		z-index: get-z-layer("blade");

		&--managed {
			left: auto;
			transform: translateX(5.6rem);
			transition:
				opacity $timing-default linear,
				transform $animate-default;
			width: calc($blade-max-width + 5.6rem);
		}
	}

	&--visible {
		pointer-events: all;
		transform: translateX(0);
		visibility: visible;
	}

	&--managed {
		width: 100%;
	}
}
