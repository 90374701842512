@use "sass:color";
@use "variables" as *;

@function as-rgb($color) {
	@return "rgb(#{color.red($color)},#{color.green($color)},#{color.blue($color)})";
}

@mixin chevron-down-background($color) {
	$col: as-rgb($color);

	background-image: url('data:image/svg+xml;utf8,<svg fill="#{$col}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 640"><path d="m297 414 23 23 181-181-45-45-136 135-136-135-45 45z"/></svg>');
}

@mixin meemoo-logo-background($color) {
	$col: as-rgb($color);

	background-image: url('data:image/svg+xml;utf8,<svg fill="#{$col}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 554 419"><path d="M277 0v203a139 139 0 1 0 0 34v182h277V0Z"/></svg>');
}
