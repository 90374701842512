@use "src/styles/abstracts" as *;

$page: "p-cp-visitor-spaces";

// TODO: make styles reusable (see visitors)

.#{$page} {
	&__header {
		width: 100%;
		height: 5.6rem;
		display: flex;

		@include respond-at("md") {
			display: flex;
			flex-flow: row-reverse nowrap;

			// Can't use &__status-filter here due to ScrollableTabs implementation
			& > div:last-child {
				flex-grow: 1;
			}
		}
	}

	&__search {
		margin-bottom: $spacer-md;

		@include respond-at("md") {
			flex-basis: 34.1rem;
			margin-bottom: 0;
			margin-left: $spacer-sm;
		}
	}

	&__actions {
		cursor: pointer;
		font-size: $font-size-lg;
		transform: translateY(0.2rem); // shift if to center on single-line
	}

	tr {
		@include respond-to("lg") {
			td,
			th {
				&:nth-child(2),
				&:nth-child(3) {
					display: none;
				}
			}
		}

		td,
		th {
			&:nth-last-child(1) {
				display: none; // Hidden, see ARC-409
				padding-left: 0;
			}
		}

		td:nth-last-child(2) {
			padding-right: 0;
			border-left: 1px solid $silver;
		}
	}

	tbody {
		tr {
			cursor: pointer;
		}
	}
}
