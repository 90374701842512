@use "src/styles/abstracts" as *;

$page: "p-cp-visitors";

// TODO: make styles reusable (see requests)

.#{$page} {
	&__header {
		@include respond-at("md") {
			display: flex;
			flex-flow: row-reverse nowrap;

			// Can't use &__status-filter here due to ScrollableTabs implementation
			& > div:last-child {
				flex-grow: 1;
			}
		}
	}

	&__search {
		margin-bottom: $spacer-md;

		@include respond-at("md") {
			flex-basis: 34.1rem;
			margin-bottom: 0;
			margin-left: $spacer-sm;
		}
	}

	&__actions {
		cursor: pointer;
		font-size: $font-size-lg;
		transform: translateY(0.2rem); // shift if to center on single-line
	}

	&__access {
		display: flex;
		align-items: center;
	}

	&__link {
		text-decoration: none;
		transition: color $animate-default;

		&:hover,
		&:active,
		&:focus,
		&:focus-within {
			color: $teal !important;
		}
	}

	tr {
		td,
		th {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}

	// tbody {
	// 	tr {
	// 		cursor: pointer;
	// 	}
	// }
}
