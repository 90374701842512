@use "src/styles/abstracts" as *;

.c-pagination {
	display: flex;
	align-items: center;
	font-size: $font-size-sm;
	color: $neutral;

	&-bar {
		display: flex;
		flex-direction: row;
		justify-content: space-between !important;
		align-items: center;
	}

	&-progress {
		color: $zinc;
		font-size: $font-size-sm;
	}

	&__btn {
		display: flex;
		justify-content: center;
		min-width: 3.2rem;
		min-height: 3.2rem;
		margin: 0 calc(#{$spacer-xs} / 4);
		cursor: pointer;

		&:focus-visible {
			outline-offset: 0.2rem;
			outline-color: $teal;
			outline-style: solid;
		}

		&--active {
			background-color: $seagreen;
			color: $white;
			font-weight: 700;
			border-radius: 50%;
		}

		&--disabled {
			color: $zinc;
			cursor: default;
		}

		&--long {
			padding: 2.5rem;
		}
	}

	&__pages {
		display: flex;
		align-items: baseline;

		.c-pagination__btn {
			align-items: center;
			width: 3.2rem;
			height: 3.2rem;
		}
	}

	&__ellipsis {
		color: $zinc;
		margin: 0 calc(#{$spacer-xs} / 4);
	}

	&__icon {
		margin-top: 0.2rem;
		font-size: $font-size-md !important; // overwrite default icon style
	}

	@include respond-to(sm) {
		&__pages,
		&__btn:first-of-type span:nth-child(2) {
			display: none;
		}

		&__btn:first-of-type {
			// lower left chevron to align it centered in mobile view
			padding: 0.2rem 0 0;
		}
	}
}
