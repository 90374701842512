@use "src/styles/abstracts" as *;

$component: "c-tooltip-component";

.#{$component} {
	background-color: $black;
	border: 1px solid $black;
	max-width: 19rem !important;
	padding: 0.5rem;
	color: $white;
	opacity: 0;
	font-size: 1.3rem;
	font-weight: 100;
	line-height: 1.428; // 20px equivalent
	transition:
		opacity 0.2s ease-in-out,
		visibility 0.2s linear 0.2s;

	&-trigger {
		display: inline-block !important;
	}

	&__arrow {
		background-color: $black;
		width: 0 !important;

		&::before {
			border: 6px solid $black !important;
		}
	}

	&[data-popper-placement^="left"] > .c-tooltip-component__arrow {
		right: 6px !important;
		transform: translate(0, -6px) !important;
		clip: unset !important;
	}

	&[data-popper-placement^="right"] > .c-tooltip-component__arrow {
		left: -6px !important;
		transform: translate(0, -6px) !important;
		clip: unset !important;
	}

	&[data-popper-placement^="top"] > .c-tooltip-component__arrow {
		bottom: -2px !important;
		transform: translate(-8px, 0) !important;
		clip: unset !important;
	}

	&--show {
		opacity: 1;
		transition-delay: 0s;
	}

	@include respond-to("md") {
		// Hide tooltips on mobile since there isn't a hover state
		// https://github.com/viaacode/hetarchief-client/pull/1090
		display: none;
	}
}
