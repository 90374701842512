@use "../abstracts" as *;

:root {
	font-size: 62.5%;
}

body {
	font-family: $font-family-primary;
	font-size: $font-size-base;
	line-height: $line-height-lg;
	margin: 0;
	position: relative;
	top: 0;
	width: 100vw;
	overflow-x: hidden;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}
