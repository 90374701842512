@use "sass:math";
@use "src/styles/abstracts" as *;

$component: "c-block-cards-without-description";
$img-size: 15.2rem;
$img-size-mobile: 10.2rem;

.#{$component} {
	width: 100%;
	container-type: inline-size;

	&__elements-wrapper {
		display: grid;
		grid-gap: $spacer-lg;
		grid-template-columns: 1fr 1fr 1fr;

		@include respond-to("md") {
			grid-gap: $spacer-sm;
		}
	}

	@container (max-width: 120rem) {
		&__elements-wrapper {
			grid-template-columns: 1fr 1fr;
		}
	}

	@container (max-width: 80rem) {
		&__elements-wrapper {
			grid-template-columns: 1fr;
		}
	}

	&__card {
		height: $img-size;
		width: 100%;
		color: $white;
		display: grid;
		grid-template-columns: repeat(2, 7.6rem) 1fr;

		@include respond-to("sm") {
			height: $img-size-mobile;
		}
	}

	a {
		text-decoration: none;
	}

	&__title {
		grid-column: 2/4;
		grid-row: 1;
		display: flex;
		align-items: center;
		padding: 0 $spacer-md 0 (math.div($img-size, 2) + $spacer-md);
		font-weight: $font-weight-bold;
		font-size: 2rem;

		@include respond-to("sm") {
			grid-column: 1/4;
			margin-left: 5rem;
		}
	}

	&__image {
		width: $img-size;
		height: $img-size;
		overflow: hidden;
		grid-column: 1/2;
		grid-row: 1;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&--round {
			border-radius: $img-size;
		}

		@include respond-to("sm") {
			width: $img-size-mobile;
			height: $img-size-mobile;
		}
	}
}
