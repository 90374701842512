@use "src/styles/abstracts" as *;

.c-video-player .c-logo-overlay .c-logo-overlay__img {
	margin: 3.2rem;
	position: absolute;
	top: 0;
	right: 0;
	width: 7%;
	opacity: 0.5;
	pointer-events: none;
}

.c-video-player-inner {
	background-color: $silver !important;
}
