@use "sass:color";
@use "../abstracts" as *;

// Wrap everything for higher specificity
.c-tags-input {
	/**
	* Elements
	*/

	@include react-select-base("c-tags-input");

	.c-tags-input__control {
		cursor: text;

		.c-tags-input__value-container .c-tags-input__multi-value {
			margin: $spacer-xxs $spacer-xs $spacer-xxs 0;
			font-size: $font-size-sm;
			background-color: transparent;
			border: none;
			border-radius: 0;
			padding: 0;
			display: inherit;
			align-items: center;
			text-decoration: none;
			font-family: inherit;
			line-height: inherit;
			color: inherit;
			word-break: break-word;

			@include respond-at(md) {
				margin: $spacer-xxs $spacer-sm $spacer-xxs 0;
			}
		}
	}

	.c-tags-input__indicator {
		display: inline-flex;
		padding: 0.6rem;

		&:not(:active, :hover):focus {
			outline: 2px solid $teal;
			border-radius: 0.5rem;
		}
	}

	.c-tags-input__clear-indicator,
	.c-tags-input__dropdown-indicator {
		cursor: pointer;

		&:hover {
			color: color.adjust($black, $alpha: -0.5);
		}
	}

	.c-tags-input__indicators {
		margin-right: 3.4rem;

		&::after {
			content: "";
			display: flex;
			width: 0.15rem;
			height: 3.5rem;
			margin: 0 $spacer-sm;
			border-radius: 0.15rem;
			background-color: $black;
			opacity: 0.12;
		}

		@include respond-at(md) {
			margin-right: 0;

			&::after {
				display: none;
			}
		}
	}

	.c-tags-input__indicator-icon {
		font-size: $font-size-lg;
		transition: color $animate-sm-in;
		color: $neutral;
	}
}
