@use "sass:map";
@use "src/styles/abstracts" as *;

// Use `hetarchief` prefix to avoid naming conflicts with avo2-components
.c-hetarchief-modal {
	background-color: $white;
	display: flex;
	flex-direction: column;
	max-height: 100%;
	max-width: 800px;
	overflow: hidden;
	width: 100%;

	&:focus,
	&:active,
	&:focus-visible,
	&:focus-within {
		outline: none;
	}

	&__overlay {
		position: fixed;
		z-index: get-z-layer("modal");
		align-items: center;
		background-color: rgb(0 0 0 / 64%);
		display: flex;
		inset: 0;
		justify-content: center;
		max-height: 100%;
		overflow: hidden;
		padding: 10px;
	}

	&__heading {
		display: flex;
		flex-grow: 1;
		flex-wrap: nowrap;
	}

	&__content {
		flex-grow: 0;
		overflow-x: hidden;
		overflow-y: auto;
	}

	&__footer {
		flex-grow: 1;
	}

	&__title {
		display: block;
		margin: 56px auto;
		max-width: 480px; // Increased from 420px as discussed with Tine Saenen
		text-align: center;

		&-wrapper {
			display: flex;
			flex-grow: 1;
			margin-left: $modal-title-margin-left;
			margin-right: 0;
			overflow: hidden;

			@include respond-at("md") {
				margin-left: $modal-title-margin-left-md;
			}
		}
	}

	&__close {
		border: 0 solid transparent;
		cursor: pointer;
		display: block;
		height: $modal-close-size;
		max-height: $modal-close-size;
		margin: $modal-close-margin;
		width: $modal-close-size;
		max-width: $modal-close-size;

		@include respond-at("md") {
			margin: $modal-close-margin-md;
		}

		&-wrapper {
			display: flex;
			flex-grow: 1;
			margin-left: 0;
			max-width: $modal-title-margin-left;

			@include respond-at("md") {
				max-width: $modal-title-margin-left-md;
			}
		}
	}
}
