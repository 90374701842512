@use "../abstracts" as *;

.p-visitor-space {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;

	&__temp-access-container {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $lila;
		padding: $spacer-xs;
		margin: $spacer-sm 0;
	}

	&__temp-access-label {
		margin-left: $spacer-xs;
	}
}

.p-visitor-space__results {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	padding-bottom: $c-feedback-button-height;

	&--placeholder {
		.l-container {
			display: flex;
			flex: 1 1 auto;

			@include respond-to("md") {
				flex-direction: column;
			}
		}
	}
}

.p-visitor-space__placeholder {
	margin: $spacer-lg * 2 0 $spacer-lg * 4;
	width: 100%;

	@include respond-at("md") {
		margin: $spacer-lg * 2 0 0;
		margin-left: $spacer-md;
		width: calc(100% / 3 * 2);
	}

	@include respond-at("lg") {
		margin: $spacer-lg * 2 0 0;
		margin-left: $spacer-md;
		width: calc(75%);
	}
}

.p-visitor-space__filter-menu {
	@include respond-at("md") {
		width: calc(100% / 3 - 2rem);
	}

	@include respond-at("lg") {
		width: calc(25% - 2.4rem);
	}
}

.p-media-card-list {
	.p-visitor-space__filter-menu {
		flex-shrink: 0;
		width: 100%;
	}
}

.p-visitor-space__searchbar {
	width: 100%;
	margin-top: $spacer-lg;

	&--has-dropdown {
		display: block;

		@include respond-at("md") {
			display: grid;
			grid-template-columns: 26.5rem auto;
		}
	}

	.c-tag-search-bar {
		&--has-info {
			width: 100%;
		}

		&--has-dropdown {
			height: 100%;
		}

		.c-tag,
		.c-tag--closable {
			height: auto;
		}
	}
}
