@use "src/styles/abstracts" as *;

.p-admin-vertalingen {
	table {
		border-spacing: 0;
		border-collapse: collapse;
	}

	thead {
		th:last-of-type {
			width: 60%;
			padding-left: 1rem;
		}
	}

	tbody {
		background-color: $white;

		@include respond-at("lg") {
			box-shadow: $shadow-24-black-08;
		}
	}

	tr {
		td {
			padding: $spacer-sm;
		}

		td:first-child {
			padding-right: 0;
		}

		td:last-child {
			padding-left: 0;
		}

		&:not(:last-of-type) {
			border-bottom: 0.1rem solid $silver;
		}
	}

	.c-keyvalue-label.c-keyvalue-path {
		font-size: $font-size-sm;
		color: $neutral;
	}

	.c-input {
		resize: none !important;

		@include reset-input-field;

		background-color: $white;
		border: 1px solid $silver;
		font-size: $font-size-base;
		outline: 1px solid transparent;
		padding: 1.4rem 1.6rem;

		&:focus {
			border-color: $teal;
		}
	}

	textarea[class*="c-input"] {
		border: none;
		font-size: $font-size-sm;
	}

	.c-toolbar {
		padding-bottom: $spacer-1xl;

		.c-toolbar__right {
			width: 100%;
			display: block;

			.c-input {
				height: 5.6rem;
				border-radius: $input-border-radius;
			}

			.o-svg-icon {
				position: absolute;
				top: calc(50% - 1rem);
				right: 2rem;

				svg {
					width: 2.4rem;
					height: 2.4rem;
				}
			}
		}
	}
}
