@use "src/styles/abstracts" as *;

$component: "c-block-three-clickable-tiles";
$img-size: 33%;

.#{$component} {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(3, 1fr);
	gap: 3.3rem;

	@include respond-to(sm) {
		grid-template-columns: 1fr;
	}

	&__link {
		text-decoration: none;

		&:hover,
		&:focus {
			.#{$component} {
				&__icon {
					border-color: $teal;
				}
			}
		}
	}

	&__tile {
		width: 100%;
		min-width: 15rem;
	}

	&__content {
		margin-top: 2.2rem;
		display: grid;
		grid-template-columns: auto $spacer-lg;
		column-gap: $spacer-md;
	}

	&__title {
		font-weight: $font-weight-bold;
		font-size: $font-size-md !important;
	}

	&__icon {
		width: $spacer-lg;
		height: $spacer-lg;
		border-radius: $spacer-lg;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $silver;
		font-size: 2rem;
		line-height: 0;
		border: 0.2rem solid transparent;
		transition: $animate-default;
		grid-column: 2;
	}

	&__image {
		aspect-ratio: 1 / 1;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
