@use "../../../../styles/abstracts" as *;

$component: "c-material-request-center-blade";

.#{$component} {
	margin-top: $c-navigation-height-md;

	@include respond-at("md") {
		margin-top: $c-navigation-height;
	}

	&__table {
		cursor: pointer;
	}

	&__loading {
		display: flex;
		justify-content: center;
		color: $black;
	}

	&__title-container {
		color: $black;
	}

	&__subtitle {
		color: $zinc;
		padding: 0 5.6rem 3.2rem 3.2rem;
	}

	&__material-container {
		display: flex;
		align-items: center;
		border-bottom: 0.2rem solid $platinum;
	}

	&__maintainer {
		padding: $spacer-sm $spacer-lg;
		background-color: $platinum;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-details {
			color: $neutral;
			text-align: end;
			font-size: $font-size-sm;
		}

		&-logo {
			position: relative;
			width: 8rem;
			height: 5rem;
		}
	}

	&__material {
		&:focus,
		&:focus-within {
			outline: 0.2rem solid $teal;
			outline-offset: -0.2rem;
		}

		&-link {
			text-decoration: none;
			padding: $spacer-md $spacer-lg;
			flex-grow: 3;
		}

		&-label {
			color: $black;
			font-weight: bold;
			margin-bottom: $spacer-xxs;
			word-break: break-word;

			&-icon {
				color: $blue;
				margin-right: $spacer-xs;
				font-size: 2.4rem;
			}
		}

		&-id {
			font-size: $font-size-sm;
			color: $neutral;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-actions {
			display: flex;
			flex-direction: row;
			padding: $spacer-md $spacer-lg;

			&-button {
				margin-right: $spacer-xs;
			}
		}
	}

	&__content {
		padding: $spacer-lg;

		dl {
			margin-top: 0;
		}

		&-value {
			margin-left: 0;
			margin-bottom: $spacer-md;
		}
	}

	&__close-button-container {
		padding: $spacer-lg;
	}

	&__send-button {
		margin-bottom: $spacer-sm;
	}

	&__no-content {
		display: flex;
		justify-content: center;
		color: $black;
		padding: $spacer-lg;
	}
}
