@use "src/styles/abstracts" as *;

$component: "c-form-control";

.#{$component} {
	&__label {
		display: block;
		font-size: $font-size-base;
		font-weight: $font-weight-bold;
		line-height: $line-height-1xl;
		margin-bottom: $spacer-sm;
	}

	&__errors {
		@extend %reset-list;

		margin-top: $spacer-sm;
		color: $cherry;
	}

	&--disabled {
		.#{$component} {
			&__label {
				color: $neutral;
				cursor: not-allowed;
			}
		}
	}

	&--label-hidden {
		.#{$component} {
			&__label {
				display: none;
			}
		}
	}
}
