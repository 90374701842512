@use "src/styles/abstracts" as *;

.p-admin-content__create,
.p-admin-content__edit {
	.c-accordion__header {
		.c-button-toolbar {
			button[class*="c-button"] {
				padding: 0;
			}
		}
	}
}

.o-form-group-layout--standard.c-content-block-form {
	h4,
	label,
	.c-accordion__header-title,
	.o-form-group__controls {
		color: $black;
	}

	.c-checkbox {
		label {
			display: flex;
			align-items: center;
		}

		input {
			margin-right: $spacer-xs;
		}
	}

	.c-button__icon.o-svg-icon svg {
		height: $button-icon-size;
		width: $button-icon-size;
	}

	.c-content-block-form-group button[class*="c-button"],
	.c-accordion__item > .u-spacer-bottom button[class*="c-button"] {
		height: 3.8rem;
		padding: 0 $spacer-md;
		border-radius: 1.9rem;
	}

	.c-content-block-form-group button[class*="c-button--active"] {
		background-color: white;
		color: $black;
	}

	.o-form-group {
		.c-input,
		.c-select .c-select__control,
		.c-color-select .react-select__control,
		.c-icon-picker .react-select__control {
			height: 3.8rem;
			border-radius: 1.9rem;
		}

		// Tag inputs
		.c-tags-input .c-tags-input__control {
			height: auto;
			padding: 10px 0;

			.c-tags-input__multi-value {
				margin-bottom: 15px;
			}
		}
	}

	.c-toolbar {
		button[class*="c-button--icon"] {
			width: 3.6rem;
			height: 3.6rem;
			border-radius: $button-radius-size;
			background-color: $silver;
			color: $black;
			border: none;

			path {
				fill: $black;
			}

			&:hover {
				background-color: $shade;
				border-color: $shade;
				color: $white;

				path {
					fill: $white;
				}
			}

			&:active {
				background-color: $teal-dark;
				border-color: $teal-dark;
				color: $white;

				path {
					fill: $white;
				}
			}

			&:focus,
			.focus-visible:focus:not(:focus-visible) {
				outline: 2px solid $teal;
			}

			&:disabled {
				border: none;
				background-color: $silver;
				color: $zinc;

				path {
					fill: $zinc;
				}
			}
		}

		.c-button-group button[class*="c-button--icon"] {
			&:first-child {
				border-radius: $button-radius-size 0 0 $button-radius-size;
			}

			&:last-child {
				border-radius: 0 $button-radius-size $button-radius-size 0;
			}
		}
	}
}
