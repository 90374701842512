@use "sass:color";
@use "src/styles/abstracts" as *;

$component: "c-filter-menu";
$c-filter-menu-flyout-top-padding: 6.4rem;
$c-filter-menu-border-color: color.adjust($shade, $alpha: -0.6);
$checkbox-size: $spacer-sm;

.#{$component} {
	background-color: $black;

	&__header {
		display: flex;
		max-height: 5.2rem;
	}

	&__view-toggle {
		padding: 0;

		:global(.c-button) {
			width: 4rem;
			height: 4rem;
		}

		&-container {
			border-left: 1px solid rgb(255 255 255 / 10%);
		}
	}

	&__option {
		position: relative;
		width: 100%;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			background-color: $teal;
			width: 0.8rem;
			transform: scaleX(0);
			transform-origin: left;
			transition: transform $animate-default;
			z-index: get-z-layer("foreground");
		}

		&--operative {
			&::before {
				transform: scaleX(1);
			}
		}
	}

	&__toggle:global(.c-button),
	&__button:global(.c-button) {
		border-radius: 0;

		&:focus,
		&:focus-visible,
		&:focus-within {
			z-index: get-z-layer("filter");
		}

		:global(.c-button__content) {
			display: flex;
			justify-content: space-between;
		}
	}

	&__toggle {
		flex: 2 0 auto;
		height: 100%;
		padding-right: 1.2rem;
		text-align: left;

		&:focus,
		&:focus-visible,
		&:focus-within {
			z-index: 2;
		}

		button {
			&:focus,
			&:focus-visible,
			&:focus-within {
				outline: 2px solid $teal;
			}
		}

		@include respond-to("md") {
			font-size: $font-size-md;
		}
	}

	&__button:global(.c-button) {
		position: relative;
		padding-right: $spacer-sm;
		font-weight: $font-weight-normal;
		border-bottom: 0.1rem solid $c-filter-menu-border-color;

		&:active {
			border-color: $black;
		}

		&:global(.c-button__icon) {
			font-size: $font-size-lg;
		}
	}

	&__button--sort:global(.c-button) {
		border-bottom-color: $c-filter-menu-border-color;
	}

	&__button--sort-header {
		background-color: $shade;
		border-bottom-color: $shade !important;

		:global(.c-button__label) {
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	&__button--active:global(.c-button) {
		z-index: get-z-layer("filter");
		background-color: $white;
		border-color: $white;
		color: $black;

		&:hover {
			background-color: $white;
			border-color: $white;
		}
	}

	&__flyout {
		&:global(.c-dropdown__content-closed) {
			position: fixed !important; // Prevent poppers from pushing when hidden
		}

		&:global(.c-dropdown__content-open) {
			z-index: get-z-layer("filter");
		}

		&--filter {
			max-width: $blade-max-width;

			// Overwrite popper styles
			min-width: $blade-max-width !important;
			max-height: 100vh;

			&:global(.c-menu) {
				padding: $c-filter-menu-flyout-top-padding 0 0;
			}
		}

		&-close {
			position: absolute;
			inset: 1.2rem 1.2rem auto auto;
			z-index: get-z-layer("filter");
		}
	}

	&__overlay {
		z-index: get-z-layer("filter-overlay");
	}

	:global(.c-dropdown__trigger:first-child + .c-dropdown__content-open) {
		:global(.c-menu) {
			margin-top: -1px;
			padding: 0;
			margin-left: $spacer-sm;

			:global(.c-button--text) {
				padding-left: $spacer-sm !important;
			}
		}
	}
}
