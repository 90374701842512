@use "sass:math";
@use "src/styles/abstracts" as *;

$component: "c-process-request-blade";

.#{$component} {
	&__info {
		background: $platinum;
		margin-bottom: $spacer-md;
		padding: $spacer-md $spacer-sm;

		@include respond-at("md") {
			padding: $spacer-md $spacer-lg;
		}

		@include respond-at("lg") {
			display: none;
		}

		&-list {
			@extend %reset-list;

			font-size: $font-size-sm;
		}

		&-item {
			strong {
				color: $neutral;
			}

			&:not(:last-child) {
				margin-bottom: math.div($spacer-md, 2);
			}
		}
	}

	&__details {
		padding: 0 $spacer-sm;

		@include respond-at("md") {
			padding: 0 $spacer-lg;
		}

		@include blade-text-styling;
	}
}
