@use "src/styles/abstracts" as *;

$component: "c-approve-request-blade";

.#{$component} {
	&__date-time {
		@include respond-at("md") {
			:global(.c-datepicker:not(.c-datepicker--time)) :global(.c-input__field) {
				border-right-width: 0;
			}
		}

		:global(.c-form-control__input) {
			@include respond-at("lg") {
				display: flex;
				flex-flow: row nowrap;
			}
		}

		:global(.react-datepicker-wrapper.c-datepicker) {
			&:not(:global(.react-datepicker-wrapper.c-datepicker--time)) {
				@include respond-to("lg") {
					margin-bottom: $spacer-sm;
				}

				@include respond-at("lg") {
					margin-right: $spacer-md;
					flex-grow: 1;
				}
			}
		}

		:global(.react-datepicker-wrapper.c-datepicker--time) {
			@include respond-at("lg") {
				flex: 0 0 14rem;
			}
		}
	}

	&__error {
		color: $cherry;
		margin-bottom: $spacer-sm;
	}
}

.c-form-control__errors {
	margin-top: -$spacer-sm !important;
	margin-bottom: $spacer-lg !important;
}
