@use "sass:map";
@use "src/styles/abstracts" as *;

$overlay-opacity-dark: 0.64;
$overlay-opacity-light: 0.4;

.c-overlay {
	z-index: get-z-layer("foreground");
	pointer-events: none;
	opacity: 0;
	position: fixed;
	inset: 0 0 -200px;
	background-color: $black;

	@keyframes fade {
		from {
			opacity: 0;
		}

		to {
			opacity: $overlay-opacity-dark;
		}
	}

	&--visible {
		pointer-events: all;

		// animation: fade 0.2s linear;
	}

	&--visible.c-overlay--dark {
		opacity: $overlay-opacity-dark;
	}

	&--visible.c-overlay--light {
		opacity: $overlay-opacity-light;
	}

	// animation types
	&--animate-default {
		transition: opacity $timing-default linear;
	}

	&--animate-sm-in {
		transition: opacity $timing-sm linear;
	}

	&--animate-sm-out {
		transition: opacity $timing-sm linear;
	}

	&--animate-md-in {
		transition: opacity $timing-md-in linear;
	}

	&--animate-md-out {
		transition: opacity $timing-md-out linear;
	}

	&--animate-lg-in {
		transition: opacity $timing-lg-in linear;
	}

	&--animate-lg-out {
		transition: opacity $timing-lg-out linear;
	}
}
