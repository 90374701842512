.c-admin__content {
	[class*="Table-module_c-table--bordered__"],
	.c-table--bordered {
		border: 0 !important;
	}

	thead tr th {
		border: 0 !important;
	}
}
