@use "src/styles/abstracts" as *;

$component: "c-notification-center";

.#{$component} {
	margin-top: $c-navigation-height-md;

	@include respond-at("md") {
		margin-top: $c-navigation-height;
	}

	&__empty,
	&__unread,
	&__read {
		padding-left: $spacer-md;
		padding-right: $spacer-md;
		padding-bottom: $spacer-md;
	}

	&__empty,
	&__unread {
		padding-top: $spacer-md;
	}

	&__empty {
		width: $blade-max-width;
		padding-top: 3.6rem;
		text-align: center;
		color: $neutral;
	}

	&__read {
		padding-top: $spacer-lg;
		background-color: $platinum;
		padding-bottom: $spacer-md;
	}

	&__header {
		margin-bottom: $spacer-md;
		font-size: $font-size-base;
		line-height: $line-height-1xl;
		text-align: left;
	}

	&__row {
		position: relative;
		align-items: center;
		display: grid;
		font-size: $font-size-xs;
		line-height: $line-height-2xl;
		grid-template-columns: auto $button-height-sm;

		&:not(:last-child) {
			padding-bottom: $spacer-md;

			&::after {
				content: "";
				display: block;
				height: 1px;
				width: 100%;
				background: $silver;
				bottom: 0;
				position: absolute;
			}
		}

		&:not(:nth-child(2)) {
			padding-top: $spacer-md;
		}

		&-title {
			margin-bottom: $spacer-xs;
			font-size: $font-size-sm;
			line-height: $line-height-4xl;
		}

		:global(.u-font-size-12 p) {
			font-size: $font-size-xs;
		}

		&-marker {
			display: inline-block;
		}
	}

	button.#{$component}__button {
		border-radius: 0;
		text-align: left;
		height: $spacer-md * 3;

		:global(.c-button__icon) {
			font-size: $spacer-md;
			margin-right: $spacer-xs;
		}
	}
}
